
.event-container {
  .header {
    height: 300px;
    background-size: cover;
    background-position: center center;
    position: relative;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0;
  }
  .description {
    font-size: 16px;
  }
  .date {
    width: 100%;
    margin-top: 20px;
    background-color: #000;
    border-radius: 10px;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: -7px 7px 10px gray;
    .month {
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.card-event {
  -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.75);
  .header {
    background-size: cover;
    background-position: center center;
    position: relative;
    margin: auto;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-end;
    color: white;
    font-weight: bold;
    padding: 0;
    span {
      display: flex;
      width: 100%;
      height: 70px;
      padding-bottom: 9px;
      align-items: flex-end;
      justify-content: center;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(0, 0, 0, 0.6) 35%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .card-body {
    .card-text {
      display: flex;
      align-items: flex-start;
      .date {
        background-color: #000;
        border-radius: 50px;
        color: white;
        font-weight: bold;
        padding: 20px;
        width: 180px;
        text-align: center;
        .month {
          font-size: 14px;
          font-weight: bold;
        }
        .day {
          font-size: 11px;
          font-weight: normal;
        }
      }
      .description {
        margin-left: 10px;
        background-color: #000;
        border-radius: 50px;
        padding: 20px;
        color: white;
        font-size: 13px;
      }
    }
  }
}

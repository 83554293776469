@import '../../assets/css/styles.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

h2 {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.stepper {
  overflow: visible !important;
  margin-bottom: 50px;
  .stepper__header {
    height: 90px;
    padding-top: 22px;
    @include media-breakpoint-down(sm) {
      button {
        padding-left: 5px;
        padding-right: 5px;
      }
      hr {
        margin: 0px;
      }
    }
  }
  .stepper__content__actions {
    justify-content: space-between;
    button span {
      margin: 0px;
    }
    @include media-breakpoint-down(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-top: 1em;
      margin-bottom: 2em;
      justify-content: center;
      button {
        margin: 3px !important;
      }
    }
  }
}
.titleResume {
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 25px;
  text-align: center;
}
.resumeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .itemsContainer {
    width: 100%;
  }
}

.payment-options {
  border: 1px solid var(--color-secundario);
  margin-top: 1em;
  padding-top: 1em;
}

.list-group .list-group-item {
  label {
    width: 100%;
  }
}

.card {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.paypal-buttons {
  display: flex !important;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  min-width: 50% !important;
  max-width: 50% !important;

  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
    min-width: 50% !important;
    max-width: 50% !important;
  }

  @include media-breakpoint-down(sm) {
    margin-left: auto;
    margin-right: auto;
    min-width: 50% !important;
    max-width: 50% !important;
  }
}

.shippingCosts {
  margin: 18px 0px 22px;
  justify-content: space-between;
  div:first-child {
    display: flex;
    max-width: 60%;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
  }
}

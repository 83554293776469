.container-slider {
  background-color: #ebecee;
  border-radius: 10px;
  box-shadow: 0 0 14px 0 var(--color-sombra);
  padding: 25px;
  margin-bottom: 35px;
  .itemCarrousel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 14px 15px rgba(21, 22, 26, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .containerFileItem {
      width: 100%;
      height: 275px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      border-radius: 10px;
      video,
      img {
        object-fit: cover;
        width: 100%;
        height: 275px;
        border-radius: 10px;
        max-width: 100%;
        top: 0%;
        left: 0%;
        overflow: hidden;
        padding-bottom: 1px;
        @media (max-width: 800px) {
          video {
            width: 80%;
          }
        }
      }
    }
    .containerTextItem {
      padding-top: 1px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      transition: all 0.3s ease-in-out;
      .textItem {
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        background: black;
        opacity: 0.8;
        box-shadow: 0 0 14px 15px rgba(21, 22, 26, 0.05);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: justify;
        padding: 15px 20px 15px 20px;
        font-family: sans-serif;
        font-weight: bolder;
      }
      @media (max-width: 800px) {
        height: auto;
        .textItem {
          font-size: 12px;
        }
      }
    }
  }
  .carousel-control-prev {
    width: 11%;
  }
  .carousel-control-next {
    width: 11%;
  }
}

@import '../../assets/css/styles.scss';

.blogContainer {
  width: 100%;
  display: flex;
  align-content: center;
  .containerPostList {
    width: 75%;
    .cardBlog {
      .img {
        height: 300px;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .columnPostList {
    width: 25%;
    height: 100%;
    // background-color: rgba($color: $primary-color, $alpha: 0.1);
    border-radius: 4px;
    // box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
    // padding: 25px 0px 25px 0px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}
.titleColumnPostTitle {
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 25px;
}
.scrollBlogContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
  overflow: auto;
  white-space: nowrap;
  @media (max-width: 600px) {
    .cardBlog {
      width: 125% !important;
      min-width: 125% !important;
    }
  }
}

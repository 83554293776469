@import './vendor/boostrap';

.store- {
  &item {
    height: 100%;
    padding-top: 1.8rem;
    display: flex;
    flex-direction: column;

    &__link {
      color: black;

      &:hover {
        color: black;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    > a {
      width: 85%;
      min-width: 90%;
      margin: auto;
      button {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &cover {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.img-container,
.cover-container {
  width: 140px;
  height: 230px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 var(--color-sombra);
  overflow: hidden;
  position: relative;
  margin: auto;
}

.cover-container {
  $p: &;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  img {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &--empty#{$p} {
    img {
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }
}

.img-container {
  cursor: pointer;

  img {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &:hover .overlay {
    opacity: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:hover span {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 0.5s ease, opacity 1.3s ease;
    transition: transform 0.1s ease, opacity 0.1s ease;
  }

  .overlay {
    opacity: 0;
    background: rgba($color: #000, $alpha: 0.4);
    position: absolute;
    margin: auto;
    width: 0px;
    height: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: 0.4s ease;
    transition: 0.2s ease;
    z-index: 1;
    pointer-events: none;
  }

  span {
    color: #fff;
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 30px;
    line-height: 30px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: transform 0.2s ease, opacity 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease;
    z-index: 2;

    h5 {
      margin: 10px;
    }

    button + a {
      margin-left: 0.5em;
    }
  }
}

.limit-long {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.img-caption {
  margin: 0;
  padding: 1em 0.8em 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;

  > div {
    flex: 1 1 auto;
    padding: 0px;
  }

  .title,
  .subtitle {
    text-align: center;
  }

  .title {
    color: #127ca6;
    font-size: 13px;
    font-family: var(--fuente-primaria);
    font-weight: bolder;
    line-height: 1.4em;
    text-transform: uppercase;
    &:only-child {
      margin-bottom: 1em;
    }
  }

  .subtitle {
    color: #011c40;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bolder;
    opacity: 0.4;
    line-height: 1.2em;
    margin-bottom: 16px;
  }

  h5,
  h6 {
    margin-bottom: 0;
    margin-top: 4px;
  }

  button {
    max-width: 322px;
    margin: auto;
  }

  @include media-breakpoint-down(md) {
    max-width: 80%;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

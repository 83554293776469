.gallery {
  :global(.carousel-inner) {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
    position: relative;

    figure {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  :global(.carousel-item) {
    background-color: rgba(black, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.menu {
  padding: 1em 0;
  > div + div {
    border-top: 4px double rgba(black, 0.05);
    margin-top: 1em;
    padding-top: 1.5em;
  }

  h4 {
    text-align: center;
  }

  ul {
    padding: 1em 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 0;
      border-bottom: 2px dotted rgba(black, 0.1);
      left: 0;
      top: 50%;
    }

    span {
      z-index: 1;
      background-color: white;
      padding: 0.5em;

      &-first-child {
        margin-right: 2em;
      }

      &:last-child {
        font-weight: 600;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}

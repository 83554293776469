.title-search {
    margin: 40px;
    justify-content: 'center';
    h2 {
        text-align: center;
    }
}

.button-search {
    width: 100%;
}
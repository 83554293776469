@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Regular.ttf);
}

body {
  font-family: Montserrat !important;
  height: 80vh !important;
}

button {
  border: medium none;
}

.container-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;

  h1 {
    font-size: 120px;
    font-weight: bold;
  }

  h2 {
    font-size: 35px;
    font-weight: 100;
  }
}

h3 {
  font-family: var(--fuente-primaria);
}

.alert-danger {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin: 10px !important;
}

.alert-success {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin: 10px !important;
}

.margin-top-sm {
  margin-top: 20px;
}
.margin-bottom-sm {
  margin-bottom: 15px;
}

.margin-top-md {
  margin-top: 40px;
}

.margin-top-xs {
  margin-top: 10px;
}

.margin-right-xs {
  margin-right: 5px;
}

.margin-right-md {
  margin-right: 40px !important;
}

.padding-top-md {
  padding-top: 40px;
}

.margin-bottom-md {
  margin-bottom: 40px;
}

.margin-md {
  margin-top: 40px;
  margin-bottom: 40px;
}

.padding-sm {
  padding: 10px;
}

.icon-md {
  font-size: 25px;
  margin: 5px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.height-limit {
  height: 100%;
}

.icon-input {
  position: absolute;
  font-size: 20px !important;
  color: #ced4da;
  left: 1.35rem;
  top: 0.5rem;
}

.icon-input-gear {
  position: absolute;
  font-size: 20px !important;
  color: #ced4da;
  right: 1.35rem;
  top: 0.5rem;
}

.input-text {
  font-size: 0.8rem !important;
  padding: 1rem 0.75rem !important;
}

.input-text-icon {
  border-radius: 1rem !important;
  font-size: 0.8rem !important;
  padding: 1rem 0.75rem !important;
  padding-left: 1.8rem !important;
}

.shadow-bottom {
  box-shadow: 0 4px 20px -10px #ced4da;
}

.bg-transparency {
  opacity: 1;
}

.title {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-family: var(--fuente-primaria);
  }
}

.divider-title {
  background: url('../img/divider.png');
  background-size: cover;
  background-position: center center;
  width: 220px;
  height: 25px;
}

.text-primary {
  color: var(--texto-primario) !important;
}

.color-primary {
  color: var(--texto-primario) !important;
}

.color-primary-c {
  color: rgba($color: #000, $alpha: 0.3);

  &:hover {
    color: var(--texto-primario);
  }
}

.text-secondary-c {
  color: rgba($color: #000, $alpha: 0.3) !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: 11px !important;
}

.v-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-more {
  text-decoration: none;
  color: rgba($color: #000, $alpha: 0.7);
  font-size: 14px;

  &:hover {
    text-decoration: none !important;
    color: var(--texto-primario);
    font-weight: bold;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.notice-message {
  margin: auto;
  padding-top: 65px;
  padding-bottom: 45px;
  text-align: center;
}

.btn-round {
  border-radius: 1rem !important;
}

.btn-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba($color: #000, $alpha: 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color: #000, $alpha: 0);
  }

  100% {
    transform: scale(0.9);
  }
}

.btn-logged {
  > button {
    background-color: var(--color-primario) !important;
    border-color: var(--color-primario);

    &:before {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0.5em;
  }
}

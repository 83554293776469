
.form-control {
  height: 38px;
  border-radius: 4px !important;
}
.mine {
	background-color: var(--color-terciario) !important;
}
.btn-primary {
	background-color: var(--color-terciario) !important;
	border-color: var(--color-terciario);
}
.btn-outline-dark:hover {
  color: var(--texto-secundario);
  background-color: var(--color-primario);
  border-color: #000;
}
.btn-outline-dark{
	color: var(--texto-primario);
	background-color: var(--color-secundario);
}


.mainrow-about {
  margin: 40px;
}

.icons-size  {
  font-size: 20px;
}

.address-info {
  display: flex;
  flex-direction: column;
  margin: 25px auto 25px;
}

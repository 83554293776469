@import '../../assets//css//styles.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.related {
  background-color: gray;
}

// h4, p {
//     text-align: justify;
// }

.title-item {
  h4 {
    text-align: start;
    font-size: 27px;
    font-weight: 700;
    font-family: sans-serif;
  }
}

.buttons-row {
  @include media-breakpoint-down(xs) {
    display: flex;
    justify-content: center;
  }
}
.price-item {
  border: 1px solid #272a59;
  border-radius: 0.2rem;
  color: #272a59;
  padding: 0.25rem 0.5rem;
  text-align: center;
  height: 31px;
  white-space: nowrap;
  max-width: 158px;
}
.autor-item {
  p {
    color: #343a40;
    font-family: sans-serif;
  }
  span {
    font-family: sans-serif;
    color: #4886d9;
    font-weight: bolder;
  }
}
.editorial-item {
  p {
    color: #343a40;
    font-family: sans-serif;
  }
  span {
    font-family: sans-serif;
    color: #1fa6a6;
    font-weight: bolder;
  }
  div:last-child {
    text-align: end;
    position: relative;
    top: 15px;
  }
}

.textButtonCart {
  font-family: sans-serif;
  font-weight: bold;
  background-color: var(--color-secundario);
  color: var(--texto-primario);
}

.buttonCart:hover {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.img-portada {
  // width: 160px;
  // height: 250px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.24);
}

.color-primary-c {
  button {
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    background: white;
    border: 1px solid #272a59;
    color: #272a59;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.description-item {
  justify-content: flex-end;
  p {
    text-align: justify;
    color: #343a40;
    font-family: sans-serif;
  }
  button {
    position: relative;
    top: -5px;
    left: -15px;
  }
}

.breadcrum-item {
  ol {
    background-color: transparent !important;
  }
}

.description {
  h5 {
    font-weight: bold;
    color: #343a40;
    font-family: sans-serif;
    text-align: center;
  }
}

.image-no-found {
  background: url('../../assets//img/image_not_found.png');
  background-size: cover;
  background-position: center center;
  width: 120px;
  height: auto;
}

.quantity-item {
  color: var(--color-primario);
  text-align: center;
  cursor: pointer;
}

.quantity-item:active {
  opacity: 0.7;
}

.detail {
  box-shadow: 0 0 14px 0 var(--color-sombra);
  border-radius: 10px;
}

.imgDetail .cover-container {
  @include media-breakpoint-only(lg) {
    // top: 90px;
    right: 18px;
    position: relative
  }
}
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

footer > .container-fluid {
  display: flex;
}

.subscription-row {
  margin-bottom: 15px;
  justify-content: center;
  background: var(--color-primario);
  width: 108%;
  padding-bottom: 65px;
  padding-top: 40px;
  @include media-breakpoint-down(xs) {
    padding-top: 15px;
    padding-bottom: 35px;
  }

  > div:nth-of-type(1) {
    padding: 15px;
    display: flex;
    justify-content: center;
    h3 {
      font-family: var(--fuente-primaria);
      color: var(--texto-secundario);
      position: relative;
      top: 3px;
      @include media-breakpoint-down(xs) {
        font-size: calc(2.2vw + 20px);
      }
    }
    div {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: center;
    right: 10px;
    @include media-breakpoint-down(md) {
      right: 0px;
    }
  }
  form {
    padding: 10px 0px;
    div > div {
      display: flex;
      padding-left: 0px;
      padding-right: 28px;
      flex-wrap: nowrap;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        padding: 0px;
      }
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
      button {
        background: var(--color-terciario);
        @include media-breakpoint-down(xs) {
          position: relative;
          top: 20px;
        }
      }
      input {
        height: 43px;
      }
    }
  }
  .alert-success {
    margin-top: 20px !important;
    max-width: 300px;
  }
}
